'use client'
import type { ControlType } from '@capabilities/offers-pipeline'
import { get, partob, toInt } from 'radash'
import type { PropsWithChildren } from 'react'
import { Button, Text, View } from 'reshaped'
import { paramKey } from '../searchParams'
import { PaginationLink } from './PaginationLink'

const ArrowPath = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0.528468 0.528514C0.788818 0.268165 1.21093 0.268165 1.47128 0.528514L5.47128 4.52851C5.73163 4.78886 5.73163 5.21097 5.47128 5.47132L1.47128 9.47132C1.21093 9.73167 0.788818 9.73167 0.528468 9.47132C0.268119 9.21097 0.268119 8.78886 0.528468 8.52851L4.05706 4.99992L0.528468 1.47132C0.268119 1.21097 0.268119 0.788864 0.528468 0.528514Z"
    fill="currentColor"
  />
)

const NextIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
  >
    {ArrowPath}
  </svg>
)

const BackIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
  >
    <g transform="rotate(180 3 5)">{ArrowPath}</g>
  </svg>
)

interface Props {
  control?: ControlType
  params: Record<string, string>
}
export const PaginationUI = ({ control, params }: Props) => {
  if (!control || control.type !== 'pagination') return null
  const page = toInt(get(params, paramKey(control)) ?? control.initialValue, 1)
  const [prev, next] = [page - 1, page + 1].map((x) =>
    x > control.pages || x < 1 ? undefined : x,
  )
  const [Prev, Next] = [prev, next].map((x) =>
    x
      ? partob(PaginationLink, { page: x })
      : ({ children }: PropsWithChildren) => <>{children}</>,
  )
  return (
    <View
      borderColor="neutral-faded"
      gap={2}
      padding={2}
      direction="row"
      borderRadius="medium"
      align="center"
      textAlign="center"
      divided={true}
      attributes={{ 'aria-label': 'Pagination' }}
    >
      <Prev>
        <Button
          variant="ghost"
          disabled={!prev}
          icon={BackIcon}
          attributes={{ 'aria-label': 'Previous Page' }}
        />
      </Prev>
      <View width={24}>
        <Text variant="body-3">
          {page} of {control.pages}
        </Text>
      </View>
      <Next>
        <Button
          variant="ghost"
          disabled={!next}
          icon={NextIcon}
          attributes={{ 'aria-label': 'Previous Page' }}
        />
      </Next>
    </View>
  )
}
