import type { Control } from '@capabilities/offers-pipeline'

export const fromSearchParams = (control: Control, searchParams: URLSearchParams) => {
  const param = control.param || control.name
  const value = searchParams.getAll(param) ?? undefined
  return value
}

export const toSearchParams = (
  control: Control,
  value: null | string,
  searchParams: URLSearchParams,
) => {
  const param = control.param || control.name
  value ? searchParams.set(param, value) : searchParams.delete(param)
  return searchParams
}

export const paramKey = (control: Control) => control.param || control.name

export const extractActiveFilters = (searchParams: Array<string>): string[] =>
  searchParams.filter((key) => /filter\[(.*)\]/.test(key))
