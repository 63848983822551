'use client'
import { useSearchParamLinkGenerator } from '@utilities/unsafe'
import Link from 'next/link'
import type { PropsWithChildren } from 'react'

interface Props {
  page: number
}
export const PaginationLink = ({ page, children }: PropsWithChildren<Props>) => {
  const linkGen = useSearchParamLinkGenerator()
  const link = linkGen({ page: page.toString() })
  return (
    // @ts-ignore
    <Link passHref href={link} legacyBehavior>
      {children}
    </Link>
  )
}
