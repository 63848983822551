'use client'
import type { ControlType } from '@capabilities/offers-pipeline'
import { useSearchParams } from 'next/navigation'
import { fromSearchParams } from '../searchParams'
import { ControlEdit } from './ControlEdit'

export type SearchParamControlEditProps = {
  control: ControlType
}

export const SearchParamControlEdit = ({ control }: SearchParamControlEditProps) => {
  const params = useSearchParams()
  const value = fromSearchParams(control, params)

  return <ControlEdit control={control} initialValue={value} />
}
