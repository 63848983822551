'use client'
import { pick } from 'radash'
import { useState } from 'react'
import { FormControl, Slider as ReshapedSlider, Text, View } from 'reshaped'

type CommonProps = {
  label: string
  name: string
  min: number
  max: number
  renderValue: ({ value }: { value: number }) => string
}

type SingleSelectionSliderProps = {
  defaultValue: number
  range: false
}

type RangedSliderProps = {
  defaultMinValue: number
  defaultMaxValue: number
  range: true
}

type SliderProps =
  | (CommonProps & SingleSelectionSliderProps)
  | (CommonProps & RangedSliderProps)

type OnChangeProps =
  | {
      minValue: number
      maxValue: number
      name: string
    }
  | {
      value: number
      name: string
    }

export const Slider = ({ label, name, min, max, renderValue, ...rest }: SliderProps) => {
  const reshapedProps = rest.range
    ? pick(rest, ['range', 'defaultMinValue', 'defaultMaxValue'])
    : pick(rest, ['range', 'defaultValue'])

  const defaultValue: [number] | [number, number] = reshapedProps.range
    ? [reshapedProps.defaultMinValue, reshapedProps.defaultMaxValue]
    : [reshapedProps.defaultValue]

  const [value, setValue] = useState(defaultValue)

  const displayValue =
    // if both slider values are the same, only display one value
    value[0] === value[1]
      ? renderValue({ value: value[0] })
      : value.map((num) => renderValue({ value: num })).join(' - ')

  const onChange = (changeArgs: OnChangeProps) => {
    if ('value' in changeArgs) {
      setValue([changeArgs.value])
    } else {
      setValue([changeArgs.minValue, changeArgs.maxValue])
    }
  }

  return (
    <FormControl>
      <View paddingBottom={3} direction="row" justify="space-between">
        <FormControl.Label>{label}</FormControl.Label>
        <Text variant="body-3">{displayValue}</Text>
      </View>
      <View
        // todo(peterliang): this is a temporary fix to make the slider not overflow, https://github.com/formaat-design/reshaped/issues/239
        paddingInline={2}
      >
        <ReshapedSlider
          name={name}
          renderValue={renderValue}
          onChange={onChange}
          min={min}
          max={max}
          step={0.1}
          {...reshapedProps}
        />
      </View>
      <View paddingTop={2} direction="row" justify="space-between">
        <Text color="neutral-faded">{renderValue({ value: min })}</Text>
        <Text color="neutral-faded">{renderValue({ value: max })}</Text>
      </View>
    </FormControl>
  )
}
