import { dash, get } from 'radash'
import { type FC, Fragment, type ReactElement } from 'react'

interface ListProps<T> {
  itemKey: keyof T
  records: Array<T>
  Component: FC<T>
}

export const List = <T extends Record<string, any>>({
  itemKey,
  records,
  Component,
}: ListProps<T>): ReactElement => {
  return (
    <>
      {records.map((item) => (
        <Fragment key={dash(get(item, itemKey.toString()))}>
          <Component {...item} />
        </Fragment>
      ))}
    </>
  )
}

type ContentListProps = {
  records: Array<string>
  Component: FC<{ content: string }>
}
export const ContentList = <T extends string>({
  records,
  Component,
}: ContentListProps) => {
  const contentPropList = records.map((content) => ({
    content: content,
  }))

  return <List records={contentPropList} Component={Component} itemKey="content" />
}
