'use client'
import type { ControlType } from '@capabilities/offers-pipeline'
import { StringFormatters, useSearchParamLinkGenerator } from '@utilities/unsafe'
import { useRouter, useSearchParams } from 'next/navigation'
import { isArray, select, sift, unique } from 'radash'
import { X as CloseIcon } from 'react-feather'
import { Badge } from 'reshaped'
import { P, match } from 'ts-pattern'
import { paramKey } from '../searchParams'

export const ControlChip = <T extends ControlType>(control: ControlType) => {
  const router = useRouter()
  const buildUrl = useSearchParamLinkGenerator()
  const params = useSearchParams()
  const value = params.getAll(paramKey(control))
  if (value === null || value.length === 0) return null
  const output = match(control)
    .returnType<string>()
    .with({ type: 'range', formatter: P.string.select('formatter') }, ({ formatter }) =>
      unique(value.map((measure) => StringFormatters[formatter](measure))).join(' - '),
    )
    .with({ type: 'choice' }, (control) => {
      const chosen = select(
        control.options,
        (x) => x.label,
        (x) => (isArray(value) ? value.includes(x.value) : x.value === value),
      )
      const base = chosen.splice(0, 1)
      const countAboveOne = chosen.length > 0 ? chosen.length : undefined
      return sift([...base, countAboveOne]).join(' + ')
    })
    .otherwise(() => (Array.isArray(value) ? value.join('') : value))

  const url = buildUrl({ [paramKey(control)]: null, page: null })
  return (
    <Badge
      color="primary"
      variant="outline"
      onClick={() => router.replace(url)}
      endIcon={CloseIcon}
    >
      {output}
    </Badge>
  )
}
