'use client'
import type {
  ControlType,
  Option as OptionsProp,
  Text,
} from '@capabilities/offers-pipeline'
import { List } from '@capabilities/ui-elements'
import { ObjectFormatters } from '@utilities/unsafe'
import { capitalize, first, isEmpty, toInt } from 'radash'
import { type PropsWithChildren, type ReactNode, useState } from 'react'
import { Checkbox, CheckboxGroup, FormControl, Select, TextField, View } from 'reshaped'
import { P, match } from 'ts-pattern'
import { paramKey } from '..'
import { Slider } from './Slider/Slider'

export type ControlEditProps = { control: ControlType; initialValue?: string[] }

const Option = (props: OptionsProp) => (
  <Checkbox value={props.value}>{props.label}</Checkbox>
)

type TextControlEditProps = PropsWithChildren<{
  name: string
  control: Text
  initialValue?: ControlEditProps['initialValue']
}>

const TextControlEdit = ({
  control: { regex },
  name,
  initialValue = [],
  children,
}: TextControlEditProps) => {
  const [hasError, setHasError] = useState(false)

  return (
    <FormControl>
      {children}
      <TextField
        name={name}
        defaultValue={initialValue[0]}
        hasError={hasError}
        onChange={({ value }) => {
          setHasError(!regex?.test(value))
        }}
      />
    </FormControl>
  )
}

export const ControlEdit = ({ control, initialValue }: ControlEditProps) => {
  const name = paramKey(control)
  const label = control.label ?? capitalize(control.name)
  const sanitizedInitialValue = initialValue?.length
    ? initialValue
    : control.initialValue
      ? [control.initialValue]
      : []

  const formControlLabel = <FormControl.Label>{label}</FormControl.Label>

  return match(control)
    .returnType<ReactNode>()
    .with({ type: 'choice', multi: true }, (control) => (
      <FormControl group>
        {formControlLabel}
        <CheckboxGroup name={name} defaultValue={sanitizedInitialValue}>
          <View gap={2}>
            <List Component={Option} records={control.options} itemKey="value" />
          </View>
        </CheckboxGroup>
      </FormControl>
    ))
    .with({ type: 'choice' }, (control) => (
      <FormControl>
        {formControlLabel}
        <Select
          name={name}
          options={control.options}
          defaultValue={sanitizedInitialValue[0]}
        />
      </FormControl>
    ))
    .with({ type: 'text' }, (control) => (
      <TextControlEdit name={name} control={control} initialValue={sanitizedInitialValue}>
        {formControlLabel}
      </TextControlEdit>
    ))
    .with({ type: 'range', operator: P.not('bt') }, (control) => {
      // determine what the default value should be if initialValue is undefined, if slider is greater than, use min, otherwise use max
      const defaultControlValue =
        control.initialValue ?? (control.operator === 'gt' ? control.min : control.max)
      // use the value from search params if it exists, otherwise use the default value
      const initial = !isEmpty(sanitizedInitialValue)
        ? toInt(first(sanitizedInitialValue))
        : defaultControlValue

      const formatter = ObjectFormatters[control.formatter]
      return (
        <Slider
          range={false}
          defaultValue={initial}
          renderValue={formatter}
          label={label}
          name={name}
          min={Math.floor(control.min)}
          max={Math.ceil(control.max)}
        />
      )
    })
    .with({ type: 'range', operator: 'bt' }, (control) => {
      const initialMin = !isEmpty(sanitizedInitialValue)
        ? toInt(sanitizedInitialValue[0])
        : control.min
      const initialMax = !isEmpty(sanitizedInitialValue)
        ? toInt(sanitizedInitialValue[1])
        : control.max
      const formatter = ObjectFormatters[control.formatter]
      return (
        <Slider
          range
          defaultMinValue={initialMin}
          defaultMaxValue={initialMax}
          renderValue={formatter}
          label={label}
          name={name}
          min={Math.floor(control.min)}
          max={Math.ceil(control.max)}
        />
      )
    })
    .otherwise(() => null)
}
