import type { Formatter } from './types'

const formatWithZeroFractionDigits = Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
})
const formatWithTwoFractionDigits = Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const asCurrency = (value: number): string => {
  const format = Number.isInteger(value)
    ? formatWithZeroFractionDigits
    : formatWithTwoFractionDigits

  return `$${format.format(value)}`
}

export const asCurrencyCentsToDollar = (cents: number): string => {
  const dollars = cents / 100
  return asCurrency(dollars)
}

export const asRate: Formatter = (value) => `${value.toFixed(2)}%`
export const basisPointsToPercentage: Formatter = (value) => `${value / 100}%`
export const Formatters = { asRate, asCurrency }
