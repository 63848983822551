import { chain, isNumber, mapValues } from 'radash'
import { Formatters } from './finance'

export const fromString = (value: string | number) =>
  isNumber(value) ? value : Number.parseFloat(value)

export const fromObject = (obj: { value: number }) => obj.value

export const ObjectFormatters = mapValues(Formatters, (fn) => chain(fromObject, fn))
export const StringFormatters = mapValues(Formatters, (fn) => chain(fromString, fn))

export type FormatterKinds = keyof typeof Formatters
